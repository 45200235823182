import React from 'react';
import './Navbar.css';
import logo from '../resources/logo.webp'

const Navbar = () => {
    return (
        <nav className="navbar is-fixed-top my-navbar" stylex={{ backgroundColor: 'red' }} role="navigation" aria-label="main navigation">

            <div className="navbar-main">
                <div className="navbar-img">
                    <img src={logo} alt="Logo" />
                </div>
                <div className="navbar-links">
                    <a href="#about">ABOUT US</a>
                    <a href="#services">OUR SERVICES</a>
                    <a href="#contact">CONTACT US</a>
                </div>

            </div>
        </nav>
    );
}

export default Navbar;
