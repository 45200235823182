import React, { useState } from 'react';
import emailjs from 'emailjs-com';

import './ContactForm.css';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        lastname: '',
        email: '',
        phone: '',
        notes: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Use your EmailJS service ID, template ID, and user ID
        const serviceID = 'service_f2dguk4';
        const templateID = 'template_amto1mb';
        const userID = '3KR-luYxZpRe1HaDs';

        emailjs.send(serviceID, templateID, formData, userID)
            .then(response => {
                console.log('SUCCESS!', response.status, response.text);
                alert('Your message has been sent successfully!');
            }, err => {
                console.error('FAILED...', err);
                alert('Failed to send the message, please try again.');
            });

        // Reset form after submission
        setFormData({
            name: '',
            lastname: '',
            email: '',
            phone: '',
            notes: ''
        });
    };

    return (
        <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)' }}>
            <div className="" style={{ maxWidth: '30%', marginRight: 'auto', marginLeft: 'auto' }}>
                <div style={{ height: '80px' }}></div>
                <div className="columns">
                    <h2 style={{ color: 'white' }}>GET IN TOUCH FOR A QUOTE</h2>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="columns">
                        <input
                            type="text"
                            name="name"
                            className="input mt-2 mr-1"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Name"
                            required
                        />
                        <input
                            type="text"
                            name="lastname"
                            className="input mt-2 ml-1"
                            value={formData.lastname}
                            onChange={handleChange}
                            placeholder="Lastname"
                            required
                        />
                    </div>

                    <div className="columns">
                        <input
                            type="email"
                            name="email"
                            className="input mt-2 mr-1"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Email"
                            required
                        />
                        <input
                            type="tel"
                            name="phone"
                            className="input mt-2 ml-1"
                            value={formData.phone}
                            onChange={handleChange}
                            placeholder="Phone"
                        />
                    </div>

                    <div className="columns">
                        <textarea
                            name="notes"
                            className="textarea mt-2"
                            value={formData.notes}
                            onChange={handleChange}
                            placeholder="Describe what you have in mind and we'll do our best to make it happen"
                            required
                        />
                    </div>
                    <div className="columns">
                        <button className="button mt-2" type="submit">Send</button>
                    </div>

                </form>
                <div style={{ height: '60px' }}></div>
            </div>
        </div>
    );
};

export default ContactForm;
