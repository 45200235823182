import React from 'react';
import mobile from '../resources/mobile.webp'
import social from '../resources/social.webp'
import website from '../resources/website.webp'

const services = [
    {
        id: 1,
        title: 'WEB APPS',
        description: 'Design and development of custom web applications tailored to meet your business needs.',
        icon: <img src={website}></img>
    },
    {
        id: 2,
        title: 'MOBILE APPS',
        description: 'Developing high-quality Android and IOS applications with intuitive user interfaces.',
        icon: <img src={mobile}></img>
    },
    {
        id: 3,
        title: 'SOCIAL MEDIA EXPANSION',
        description: 'Strategic social media campaigns to enhance your brand presence and engage with your audience.',
        icon: <img src={social}></img>
    }
];

const Services = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h2>OUR SERVICES</h2>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                {services.map(service => (
                    <div key={service.id} style={{ margin: '10px', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', width: '25vw', textAlign: 'center' }}>
                        <div style={{ fontSize: '40px' }}>{service.icon}</div>
                        <h3 style={{ fontSize: '22px' }}>{service.title}</h3>
                        <p style={{ fontSize: '18px' }}>{service.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Services;
