import React from 'react';

const AboutUs = () => {
    return (
        <div className="section" style={{ maxWidth: '80vw', marginRight: 'auto', marginLeft: 'auto' }}>
            <div style={{ height: '60px' }}></div>
            <p style={{ fontSize: '18px' }}>
                Everbright Studio is a dynamic IT company, dedicated to crafting state-of-the-art applications and delivering exceptional services. Our young, talented team is committed to innovation, quality, and affordability, making top-tier technology accessible to businesses of all sizes. We believe in a client-centric approach, tailoring solutions to meet specific goals and challenges. Our expertise spans mobile and web app development, cloud services, and data analytics, ensuring we're equipped to handle diverse IT needs. By maintaining the highest standards and embracing the latest technologies, we ensure our solutions are not only advanced but also sustainable and secure. At Everbright Studio, we're not just service providers; we're partners in your digital journey, dedicated to helping you achieve success in the digital landscape. Let's embark on this journey together and unlock the potential of your business with Everbright Studio, where your vision becomes our mission.
            </p>
        </div>
    );
};

export default AboutUs;