import "./App.css";

import Navbar from './components/Navbar';
import Header from "./components/Header";
import Services from "./components/Services";
import ContactForm from "./components/ContactForm";
import Footer from "./components/Footer";
import 'bulma/css/bulma.min.css';
import AboutUs from "./components/AboutUs";
import Techs from "./components/Techs";


function App() {
 

  return (

    <div>
      <Navbar />
      <Header />
      <AboutUs />
      <Services />
      <Techs />
      <ContactForm />
      <Footer />
     

      {/* <div style={{ backgroundColor: "rgba(50, 50, 50, 0.8)", position: 'absolute', width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

        <div style={{ background: 'rgb(200, 200, 200)', width: 400, height: 200, borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', position: 'relative' }}>

          <h1>SORRY</h1>
          <h2 style={{ position: 'relative', bottom: 20 }}>The site is under construction</h2>
          <img src="gear.png" style={{ height: 50, position: 'absolute', top: -10, left: -10 }} alt="" />
          <img src="gear.png" style={{ height: 50, position: 'absolute', top: -10, right: -10 }} alt="" />
          <img src="tools.png" style={{ height: 20, position: 'absolute', bottom: 30 }} alt="" />
        </div>
      </div> */}
    </div>
  );
}

export default App;
