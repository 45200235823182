import React from 'react';
import AI from '../resources/ai.webp'
import Handshake from '../resources/handshake.webp'

const Techs = () => {
    return (
        <div className="section" style={{ maxWidth: '60vw', marginRight: 'auto', marginLeft: 'auto' }}>
            <div style={{ height: '80px' }}></div>
            <div className="columns">
                <div className="column" style={{ display: 'flex' }}>
                    <img src={AI} style={{ maxWidth: '25vw', marginRight: 'auto', marginLeft: 'auto' }}></img>

                </div>
                <div className="ml-1 column" style={{ display: 'flex' }}>
                    <p style={{ fontSize: '18px', marginTop: 'auto', marginBottom: 'auto' }} >
                        At Everbright Studio, we harness the power of AI and cutting-edge technologies to craft unparalleled solutions for our clients. Our approach combines advanced algorithms, machine learning, and innovative tech to analyze trends, predict outcomes, and automate processes. This enables us to deliver customized, efficient, and scalable solutions that drive growth and success. Our commitment to staying at the forefront of technological advancements ensures that our clients benefit from the latest innovations, from AI-driven data analytics to IoT integrations. By leveraging these tools, we empower businesses to overcome challenges, seize opportunities, and achieve their strategic objectives in the digital age.
                    </p>

                </div>
            </div>
            <div className="columns">
                <div className="column" style={{ display: 'flex' }}>

                    <p style={{ fontSize: '18px', marginTop: 'auto', marginBottom: 'auto' }} >At Everbright Studio, our compact size is our strength, allowing us to offer personalized, attentive customer service. We believe in working hand-in-hand with our clients, truly understanding their unique needs and challenges. Our approach is collaborative and flexible, ensuring that we craft bespoke solutions that align perfectly with our clients' goals. This close partnership enables us to respond swiftly and effectively, making adjustments as needed to deliver optimal results. Our commitment to excellence and client satisfaction drives us to go above and beyond, ensuring that every solution we provide not only meets but exceeds expectations, fostering lasting relationships built on trust and mutual success.</p>
                </div>
                <div className="ml-1 column" style={{ display: 'flex' }}>

                    <img src={Handshake} style={{ maxWidth: '25vw', marginRight: 'auto', marginLeft: 'auto' }}></img>
                </div>
            </div>
            <div style={{ height: '80px' }}></div>
        </div>
    );
};

export default Techs;